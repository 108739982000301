import { format } from 'date-fns';
import styles from '@/sass/variables.scss';

export default {
  omwServer: {
    url:
      'https://0d11ha7xpe.execute-api.eu-west-2.amazonaws.com/dev/onmyway?token',
    apiKey: 'cb94d0fe-23e8-4481-ab11-226841255daf',
  },
  here: {
    apiKey: 'Q977kVgv3BgJSo89bM6KRNDnn2F0lqgl8_2EI1Sdv_s',
    mapHeightMobile: '40vh',
    mapHeightDesktop: '65vh',
    mapWidthMobile: '93vw',
    mapWidthDesktop: '47vw',
    mapWidth: '100%',
    imperial: true,
    showZoom: true,
    majorVersion: 'v3',
    minorVersion: '3.1.20.0',
    centreLat: 53.361463,
    centreLng: -1.67194,
    defaultZoom: 14,
  },
  marketing: {
    enabled: true,
    customer: 'leadent',
    retrievalUrl:
      'https://portal-be-dev.leadentgateway.digital/imageUpload/published',
    recordClickUrl:
      'https://portal-be-dev.leadentgateway.digital/imageUpload/clicked',
    fieldName: 'category',
    demo: true,
    demoCustomer: 'acme',
  },
  feedback: {
    enabled: true,
    url: 'https://x9i589rg9f.execute-api.eu-west-2.amazonaws.com/Prod/save',
    timeout: 5000,
  },
  intercom: {
    enabled: true,
    appId: 'rx6jmmc5',
    onlineDays: {
      1: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      2: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      3: {
        showTime: '09:00',
        hideTime: '17:49',
      },
      4: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      5: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      6: {
        showTime: '09:00',
        hideTime: '16:00',
      },
    },
    // onlineBuckets: []
  },
  trustpilot: {
    enabled: false,
    // dataLocale: '',
    // dataTemplateId: '',
    // dataBusinessUnitId: '',
    // dataStyleHeight: '',
    // dataStyleWidth: '',
    // dataTheme: ''
  },
  logos: [
    {
      label: 'NVQ Accredited',
      value: 'Elevator Maintenance',
    },
    {
      filename: 'iso-logo-print.gif',
      label: 'ISO Accredited',
      value: 'ISO 8100-20:2018',
    },
  ],
  reschedule: {
    url: 'https://mighty-shore-86757.herokuapp.com/',
    showForToday: true,
    showForRoutePositionLT: 1,
  },
  display: {
    eta: {
      useCalculatedEta: true,
      bufferBeforeMins: 10,
      bufferAfterMins: 10,
    },
    defaultEngineerName: 'Your technician',
    customerWebsite: 'https://www.leadent.digital',
    notification: {
      show: true,
      title: 'Coronavirus/COVID-19',
      message: `<b>The health and safety of our team is our number one priority.
      Due to the recent coronavirus outbreak and in line with government advice, if you are self-isolating, experiencing any symptoms of coronavirus,
      or have knowingly come into contact with anyone who has, we ask you to let us know so we can take necessary precautions<b>`,
    },
    showLicensePlate: true,
    licencePlateCountry: 'xx',
    engineerIcon: {
      opacity: 0.9,
      svg: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="506.38" height="512" viewBox="0 0 506.38 512">
      <title>van2</title>
      <g>
        <path d="M500.75,164.92a8.44,8.44,0,0,0-8.44,8.44v47.83H444.48v16.88h56.27a8.44,8.44,0,0,0,8.44-8.44V173.36A8.44,8.44,0,0,0,500.75,164.92Z" transform="translate(-2.81)" style="fill: ${styles.engineerMarker}"/>
        <path d="M19.69,221.19V173.36a8.44,8.44,0,1,0-16.88,0v56.27a8.44,8.44,0,0,0,8.44,8.44H67.52V221.19Z" transform="translate(-2.81)" style="fill: ${styles.engineerMarker}"/>
      </g>
      <g>
        <rect x="375.38" y="430.64" width="77.54" height="81.36" style="fill: #0e0a57"/>
        <rect x="53.45" y="430.64" width="77.54" height="81.36" style="fill: #0e0a57"/>
      </g>
      <path d="M434,22l14.51,68L256,123.78,63.52,90,78,22C80.6,9.9,92.85,0,105.22,0H406.77C419.15,0,431.4,9.9,434,22Z" transform="translate(-2.81)" style="fill: ${styles.engineerMarker}"/>
      <polygon points="445.67 90.02 60.71 90.02 30.95 229.62 30.95 316.27 142.25 361.34 187.26 361.34 253.19 332.08 319.12 361.34 364.13 361.34 475.43 316.27 475.43 229.62 445.67 90.02" style="fill: ${styles.engineerMarker}"/>
      <g>
        <path d="M478.24,316.27v45.07l-55.65,22.5-55.65-22.5,11-29.26c3.25-8.7,13.52-15.81,22.8-15.81Z" transform="translate(-2.81)" style="fill: #ff7039"/>
        <path d="M134.1,332.08l11,29.26-55.65,22.5-55.65-22.5V316.27H111.3C120.58,316.27,130.85,323.38,134.1,332.08Z" transform="translate(-2.81)" style="fill: #ff7039"/>
      </g>
      <polygon points="330.08 332.08 319.12 361.34 253.19 383.84 187.26 361.34 176.3 332.08 330.08 332.08" style="fill: #fff"/>
      <path d="M33.76,361.34v69.3a22.57,22.57,0,0,0,22.5,22.51H455.74a22.57,22.57,0,0,0,22.5-22.51v-69.3Z" transform="translate(-2.81)" style="fill: ${styles.engineerMarker}"/>
      <path d="M122.56,416.53h-55a8.44,8.44,0,1,1,0-16.88h55a8.44,8.44,0,0,1,0,16.88Z" transform="translate(-2.81)" style="fill: #fff"/>
      <path d="M344.43,416.53H167.57a8.44,8.44,0,0,1,0-16.88H344.43a8.44,8.44,0,0,1,0,16.88Z" transform="translate(-2.81)" style="fill: ${styles.engineerMarker}"/>
      <g>
        <path d="M444.48,416.53h-55a8.44,8.44,0,0,1,0-16.88h55a8.44,8.44,0,1,1,0,16.88Z" transform="translate(-2.81)" style="fill: #fff"/>
        <polygon points="73.99 106.9 47.83 229.62 458.55 229.62 432.39 106.9 73.99 106.9" style="fill: #fff"/>
      </g>
    </svg>`,
      width: 30,
      height: 30,
      anchorX: 20,
      anchorY: 25,
    },
    activityIcon: {
      opacity: 0.4,
      svg: `<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="563.44257" height="792.94273" viewBox="0 0 47 60">
              <title>flag2</title>
              <rect x="2" y="4" width="45" height="32" style="fill: ${styles.homeMarker}"/>
              <path d="M7.5,0a1,1,0,0,0-1,1V59a1,1,0,0,0,2,0V1A1,1,0,0,0,7.5,0Z" transform="translate(-6.5)" style="fill: #424a60"/>
            </svg>`,
      width: 30,
      height: 50,
      anchorX: 0,
      anchorY: 50,
    },
    contactCentreNo: '01784 278222',
    loadingLogo: 'AcmeElevators.png',
    companyLogo: 'AcmeElevators.png',
    headerHeight: '6em',
    imageHeight: '3.5em',
    // imageWidth: '10em',
    footerText: `
      <div style="white-space:nowrap">© IFS {year} - Appointment Assistant</span>
        <span style="white-space:nowrap">
      <br/></div>`,
  },
  showDeliveryForFuture: false,
  pending: {
    line1_1: 'expects to be with you between',
    line1_2: 'and',
    line1_3: 'and has',
    line1_4: 'stops before yours',
    line1_4_singular: 'stop before yours',
    line1_5: ' and is on their way to you now',
    timeslot_1: 'Your appointment was originally booked for delivery between',
    timeslot_2: 'and',
  },
  pendingFuture: {
    line1_1: 'We will be with you between',
    line1_2: 'and',
    line1_3: 'on',
    line1_4: `and you'll be able to track progress and see your delivery window on the day`,
  },
  started: {
    line1_1: 'has arrived at',
  },
  completed: {
    line1: 'Your appointment was completed by',
  },
  cancelled: {
    line1_1: 'Your appointment was cancelled. Please contact us on',
    line1_2: 'if you have any questions',
  },
  aborted: {
    line1_1:
      'Your technician was unable to complete your appointment. Please call',
    line1_2: 'to rearrange quoting your appointment number:',
  },
  notDone: {
    line1_1: 'The technician was unable to complete the job today',
  },
  suspended: {
    line1_1: 'The technician will be visiting today and will contact you',
  },
  general: {
    addressToday: 'is heading to',
    addressFuture: 'will be heading to',
  },
};
