<template>
  <div class="columns is-mobile">
    <div class="column is-1">
      <div class="circle legend-best-eco mb-2" />
      <div class="circle legend-eco mb-2" />
      <div class="circle legend-normal mb-2" />
    </div>
    <div class="column is-11">
      <div>
        {{ $t('Availability with lots of appointments in your area') }}
      </div>
      <div>
        {{ $t(`Availability and we're in your area`) }}
      </div>
      <div>
        {{ $t(`Appointments available but we're not in your area`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppointmentLegend',
};
</script>

<style>
.circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.legend-eco {
  background-color: #2b6cb0;
}
.legend-normal {
  background-color: #dd6b20;
}
.legend-best-eco {
  background-color: #38a169;
}
</style>
