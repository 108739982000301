<template>
  <div class="columns">
    <div class="column"></div>
    <div class="column is-full">
      <b-notification :closable="false" type="is-success" has-icon>
        <p class="has-text-weight-semibold is-size-4">
          {{ $t('Your appointment has been rescheduled to') }}:
        </p>
        <br />
        <p class="has-text-weight-semibold is-size-4">
          Date:
          {{ formattedDate }}
        </p>
        <p class="has-text-weight-semibold is-size-4">
          {{ $t('Time Slot') }}: {{ timeslotLabel }}
        </p>
      </b-notification>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppointmentConfirmation',
  computed: {
    ...mapGetters(['rescheduledDate', 'rescheduledTimeslot']),
    timeslotLabel() {
      if (!this.rescheduledTimeslot) return '';
      return this.rescheduledTimeslot.label;
    },
    formattedDate() {
      return this.$d(new Date(this.rescheduledDate));
    },
  },
};
</script>
