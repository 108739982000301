<template>
  <div
    ref="apptDetailsContainer"
    class="appointment-details-position appointment-details-style"
    :style="appointmentDetailsWidth"
    @click="toggleShow"
  >
    <div style="position: relative">
      <div v-show="!appointmentDetailsShowing" class="appointment-text">
        <b-icon icon="chevron-double-down" size="is-small" />
      </div>
      <div v-show="appointmentDetailsShowing" class="appointment-text">
        <b-icon icon="chevron-double-up" size="is-small" />
      </div>
      <div v-if="!appointmentDetailsShowing" class="appointment-header-text">
        <p class="has-text-weight-semibold">
          {{ $t('Tap to see appointment timings') }}
        </p>
      </div>
      <div v-else class="appointment-header-text">
        {{ $t('Tap to hide') }}
      </div>
      <div
        ref="appointmentDetails"
        class="appointment-details-collapse appointment-text"
      >
        <span>
          {{ $t('Your appointment number') }}:
          <span class="has-text-weight-semibold">
            {{ activityDetails.apptNumber }}
          </span>
        </span>
        <hr :style="horizontalRuleStyle" />
        <!-- eslint-disable -->
        <div v-html="line1"></div>
        <!-- eslint-enable -->
        <hr :style="horizontalRuleStyle" />
        <!-- eslint-disable -->
        <span v-if="address != null" v-html="address" />
        <!-- eslint-enable -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import { mapGetters, mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import styles from '@/sass/variables.scss';

export default defineComponent({
  props: {
    width: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      styles,
      appointmentDetailsShowing: undefined,
      photoSource: undefined,
      line1: undefined,
      address: undefined,
      timeslot: undefined,
    };
  },
  computed: {
    ...mapGetters(['formattedDate', 'apptInFuture', 'calculatedEta']),
    relativePositionInRoute() {
      return this?.activityDetails?.positionInRoute - 1;
    },
    appointmentDetailsWidth() {
      return {
        width: this.width
      };
    },
    horizontalRuleStyle() {
      return {
        backgroundColor: styles.appointmentDetailsPanel,
        padding: 0,
        margin: 0,
        border: 0,
        borderTop: '1px dotted rgba(207, 212, 214) !important'
      };
    },
    appointmentDetailsStyle() {
      return {
        color: 'white',
      };
    },
  },
  watch: {
    activityDetails: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.activityDetails || !this.activityDetails.status) return;
        const status = this.activityDetails.status.toLowerCase();
        switch (status) {
          case 'pending': {
            this.line1 = this.buildPendingStatusText();
            this.address = this.buildAddress();
            this.timeslot = this.buildTimeslot();
            break;
          }
          case 'started': {
            this.line1 = this.buildStartedStatusText();
            break;
          }
          case 'completed': {
            this.line1 = this.buildCompleteStatusText();
            this.address = undefined;
            break;
          }
          case 'cancelled': {
            this.line1 = this.buildCancelledStatusText();
            this.address = undefined;
            break;
          }
          case 'canceled': {
            this.line1 = this.buildCancelledStatusText();
            this.address = undefined;
            break;
          }
          case 'aborted': {
            this.line1 = this.buildAbortedStatusText();
            this.address = undefined;
            break;
          }
          case 'not done': {
            this.line1 = this.buildNotDoneStatusText();
            this.address = undefined;
            break;
          }
          case 'notdone': {
            this.line1 = this.buildNotDoneStatusText();
            this.address = undefined;
            break;
          }
          case 'suspended': {
            this.line1 = this.buildSuspendedStatusText();
            break;
          }
        }
        this.$forceUpdate();
      },
    },
  },
  mounted() {
    const apptDetailsEl = this.$refs.appointmentDetails;
    apptDetailsEl.style.maxHeight = null;
  },
  methods: {
    toggleShow() {
      const apptDetailsEl = this.$refs.appointmentDetails;
      const apptDetailsContainer = this.$refs.apptDetailsContainer;
      if (apptDetailsEl.style.maxHeight) {
        apptDetailsEl.style.maxHeight = null;
        apptDetailsContainer.style.opacity = 0.75;
        this.appointmentDetailsShowing = false;
      } else {
        apptDetailsEl.style.maxHeight = apptDetailsEl.scrollHeight + 'px';
        apptDetailsContainer.style.opacity = 1.0;
        this.appointmentDetailsShowing = true;
      }
    },
    buildPendingStatusText() {
      // Check to see if this activity is in the future
      if (this.apptInFuture) {
        return this.buildPendingFutureText();
      } else {
        return this.buildPendingTodayText();
      }
    },
    buildPendingFutureText() {
      let output = `<div>${this.$t(
        this.$omwConfig.pendingFuture.line1_1,
      )} `;
      output += `<span style="color:white; font-weight: bolder">${this.$omwConfig.showDeliveryForFuture
        ? this.activityDetails.trimDeliveryWindowStart
        : this.activityDetails.trimServiceWindowStart
        }</span>`;
      output += ` ${this.$t(this.$omwConfig.pendingFuture.line1_2)} `;
      output += `<span style="color:white; font-weight: bolder">${this.$omwConfig.showDeliveryForFuture
        ? this.activityDetails.trimDeliveryWindowEnd
        : this.activityDetails.trimServiceWindowEnd
        }</span>`;
      output += ` ${this.$t(this.$omwConfig.pendingFuture.line1_3)}`;
      output += ` <span style="color:white; font-weight: bolder">${this.formattedDate}</span>`;
      output += ` ${this.$t(this.$omwConfig.pendingFuture.line1_4)}`;
      return output;
    },
    buildPendingTodayText() {
      let arrivalTimeEnd, arrivalTimeStart;
      // If this job is not the next in the engineer's route, or we don't have ETA calculation turned on, use the provided value
      if (this.relativePositionInRoute !== 0 || !this.$omwConfig?.display?.eta?.useCalculatedEta) {
        arrivalTimeStart = this.activityDetails.trimDeliveryWindowStart;
        arrivalTimeEnd = this.activityDetails.trimDeliveryWindowEnd;
      }
      else {
        // If this job is the next in the engineer's route, use an ETA calculated from the map provider
        arrivalTimeStart = this?.calculatedEta?.bufferedStartDate || this.activityDetails.trimDeliveryWindowStart; // fall back to provided value if necessary
        arrivalTimeEnd = this?.calculatedEta?.bufferedEndDate || this.activityDetails.trimDeliveryWindowEnd; // fall back to provided value if necessary
      }

      let output = `<span>${this.engineerName
        } ${this.$t(this.$omwConfig.pending.line1_1)} `;
      output += `<span style="color:white; font-weight: bolder">${arrivalTimeStart}</span>`;
      output += ` ${this.$t(this.$omwConfig.pending.line1_2)} `;
      output += `<span style="color:white; font-weight: bolder">${arrivalTimeEnd}</span>`;

      if (this.relativePositionInRoute === 0) {
        output += `${this.$t(this.$omwConfig.pending.line1_5)}`;
      } else if (this.relativePositionInRoute === 1) {
        output += ` ${this.$t(this.$omwConfig.pending.line1_3)} `;
        output += `<span style="color:white; font-weight: bolder">${this.relativePositionInRoute}</span>`;
        output += ` ${this.$t(this.$omwConfig.pending.line1_4_singular)}`;
      } else if (this.relativePositionInRoute < 0) {
        return output;
      } else {
        output += ` ${this.$t(this.$omwConfig.pending.line1_3)} `;
        output += `<span style="color:white; font-weight: bolder">${this.relativePositionInRoute}</span>`;
        output += ` ${this.$t(this.$omwConfig.pending.line1_4)}`;
      }
      output += `</span>`;
      return output;
    },
    buildStartedStatusText() {
      if (this.engineerDetails) {
        this.address = this.buildAddressArrived();
        let output = `<div>${this.engineerName}`;
        output += ` ${this.$t(this.$omwConfig.started.line1_1)}`;
        return output;
      }
    },
    buildCompleteStatusText() {
      let output = `<div> ${this.$t(
        this.$omwConfig.completed.line1,
      )} ${this.engineerName}`;
      output += '</div>';
      return output;
    },
    buildCancelledStatusText() {
      let output = `<div>${this.$t(
        this.$omwConfig.cancelled.line1_1,
      )}`;
      output += ` <span style="color:white; font-weight: bolder">${this.$omwConfig.display.contactCentreNo}</span>`;
      output += ` ${this.$t(this.$omwConfig.cancelled.line1_2)}</div>`;
      return output;
    },
    buildAbortedStatusText() {
      if (this.activityDetails) {
        let output = `<div>${this.$t(
          this.$omwConfig.aborted.line1_1,
        )}`;
        output += ` <span style="color:white; font-weight: bolder">${this.$omwConfig.display.contactCentreNo}</span>`;
        output += ` ${this.$t(this.$omwConfig.aborted.line1_2)}`;
        output += ` <span style="color:white; font-weight: bolder">${this.activityDetails.apptNumber}</span>`;
        output += '</div>';
        return output;
      }
    },
    buildNotDoneStatusText() {
      if (this.activityDetails) {
        let output = `<div> ${this.$t(
          this.$omwConfig.notDone.line1_1,
        )} </div>`;
        return output;
      }
    },
    buildSuspendedStatusText() {
      if (this.activityDetails) {
        let output = `<div> ${this.$t(
          this.$omwConfig.suspended.line1_1,
        )} </div>`;
        return output;
      }
    },
    buildAddress() {
      if (this.engineerDetails && this.activityDetails) {
        let address = `<p>`;
        if (!this.apptInFuture) {
          address += `${this.engineerName} ${this.$t(
            this.$omwConfig.general.addressToday,
          )}`;
          address += ` <span style="color:white; font-weight: bolder">${this.activityDetails.streetAddress}, ${this.activityDetails.city}, ${this.activityDetails.postalCode}</span>`;
          return address;
        } else {
          address += `${this.$t('Your engineer')} ${this.$t(
            this.$omwConfig.general.addressFuture,
          )}`;
          address += ` <span style="color:white; font-weight: bolder">${this.activityDetails.streetAddress}, ${this.activityDetails.city}, ${this.activityDetails.postalCode}</span>`;
          address += '</p>';
          return address;
        }
      }
    },
    buildAddressArrived() {
      if (this.activityDetails) {
        let address = `<p><span style="color:white; font-weight: bolder">${this.activityDetails.streetAddress}, ${this.activityDetails.city}, ${this.activityDetails.postalCode}</span>`;
        address += '</p>';
        return address;
      }
    },
    buildTimeslot() {
      if (this.activityDetails) {
        let timeslot = `${this.$t(
          this.$omwConfig.pending.timeslot_1,
        )} <span style="color:white; font-weight: bolder">${this.activityDetails.trimServiceWindowStart
          }</span>`;
        timeslot += ` ${this.$t(
          this.$omwConfig.pending.timeslot_2,
        )} <span style="color:white; font-weight: bolder">${this.activityDetails.trimServiceWindowEnd
          }</span>`;
        return timeslot;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.appointment-details-position {
  position: absolute;
  // transform: translateY(30%);
  left: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 10;
}
.appointment-details-style {
  border-radius: 5px 5px 5px 5px;
  color: $appointment-details-panel-text;
  background-color: $appointment-details-panel;
  opacity: 0.75;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.appointment-details-collapse {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.appointment-text {
  padding-left: 0.5em;
  float: left;
  font-size: 1.1em;
}

.appointment-header-text {
  font-style: italic;
  padding-left: 1em;
  float: left;
}
</style>
