<template>
  <div>
    <div
      ref="map"
      class="here-map"
      :style="{ width, height }"
      style="display: inline-block; cursor: grab"
    />
    <p
      v-if="showDisclaimer"
      class="is-size-6 has-text-centered has-text-black"
      style="padding-top: 0.5em"
    >
      {{
        $t(
          'This is an estimated time and subject to change, please refresh the page for updates.',
        )
      }}
    </p>

    <div
      class="buttons"
      style="justify-content: center; padding-top: 0.5em; padding-bottom: 0.8em"
    >
      <b-button
        type="is-omw-buttons"
        class="has-text-weight-semibold custom-button"
        @click="recenter"
      >
        {{ $t('Centre map') }}
      </b-button>
      <b-button
        type="is-omw-buttons"
        class="has-text-weight-semibold custom-button"
        @click="getData(true)"
      >
        {{ $t('Update') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { supportsPassiveEvents } from 'detect-passive-events';
import { defineComponent } from '@vue/composition-api';
import styles from '@/sass/variables.scss';
import { loadCss, loadJs } from '@/utils/index';

export default defineComponent({
  name: 'HereMap',
  props: {
    apiKey: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    imperial: {
      type: Boolean,
      default: true,
    },
    showZoom: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      routeColor: styles.route,
      pixelRatio: 1.0,
      showLegend: false,
      map: {},
      platform: {},
      defaultLayers: {},
      behavior: {},
      markerGroup: {},
      router: {},
      ui: {},
      center: {},
      routeLine: {},
      mapsCoreLoaded: false,
      mapServiceLoaded: false,
      mapUiLoaded: false,
      mapEventsLoaded: false,
      mapUiCssLoaded: false,
      initComplete: false,
      engineerMarker: undefined,
      activityMarker: undefined,
    };
  },
  computed: {
    ...mapGetters(['apptInFuture', 'engineerDetails', 'activityDetails']),
    zoomLevel() {
      return !this.bothMarkersPresent ? 16 : undefined;
    },
    coreLoaded() {
      return (
        this.mapsCoreLoaded &&
        this.mapServiceLoaded &&
        this.mapUiLoaded &&
        this.mapEventsLoaded &&
        this.mapUiCssLoaded
      );
    },
    bothMarkersPresent() {
      if (this.activityMarker && this.engineerMarker) {
        return true;
      }
      return false;
    },
    showEngineerMarker() {
      return (
        !this.apptInFuture &&
        this.engineerDetails &&
        this.engineerDetails.latitude &&
        this.engineerDetails.longitude
      );
    },
    showHomeMarker() {
      return (
        this.activityDetails &&
        this.activityDetails.latitude &&
        this.activityDetails.longitude
      );
    },
  },
  watch: {
    initComplete: {
      handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          this.$forceUpdate();
        }
      },
    },
    center: {
      handler(newVal) {
        this.map
          .getViewModel()
          .setLookAtData({ bounds: newVal, zoom: this.zoomLevel });
      },
    },
    engineerDetails: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.getMap(() => {
          this.initMap(() => {
            this.setEngineerMarker(newVal);
          });
        });
      },
    },
    activityDetails: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.getMap(() => {
          this.initMap(() => {
            this.setActivityMarker(newVal);
          });
        });
      },
    },
  },
  beforeCreate() {
    const majorVersion = this.$omwConfig.here.majorVersion;
    const minorVersion = this.$omwConfig.here.minorVersion;
    const url = `https://js.api.here.com/${majorVersion}/${minorVersion}`;
    loadJs(
      `${url}/mapsjs-core.js`,
      () => {
        this.mapsCoreLoaded = true;
      },
      document.body,
      false,
      true,
    );
    loadJs(
      `${url}/mapsjs-service.js`,
      () => {
        this.mapServiceLoaded = true;
      },
      document.body,
      false,
      true,
    );
    loadJs(
      `${url}/mapsjs-ui.js`,
      () => {
        this.mapUiLoaded = true;
      },
      document.body,
      false,
      true,
    );
    loadJs(
      `${url}/mapsjs-mapevents.js`,
      () => {
        this.mapEventsLoaded = true;
      },
      document.body,
      false,
      true,
    );
    loadCss(
      `${url}/mapsjs-ui.css`,
      () => {
        this.mapUiCssLoaded = true;
      },
      document.head,
    );
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleZoomOption() {
      if (!this.showZoom) {
        const zoom = this.ui.getControl('zoom');
        zoom.setDisabled(true);
        zoom.setVisibility(false);
        this.behavior.disable(window.H.mapevents.Behavior.WHEELZOOM);
        this.behavior.disable(window.H.mapevents.Behavior.PINCH_ZOOM);
        this.behavior.disable(window.H.mapevents.Behavior.DBL_TAP_ZOOM);
        this.behavior.enable(window.H.mapevents.Behavior.DRAGGING);
      }
    },
    handleResize() {
      this.map.getViewPort().resize();
    },
    initMap(callback) {
      if (this.initComplete) callback();
      else {
        const defaultCentreLat = this.$omwConfig.here.centreLat;
        const defaultCentreLng = this.$omwConfig.here.centreLng;
        const defaultZoom = this.$omwConfig.here.defaultZoom;
        this.platform = new H.service.Platform({
          apikey: this.apiKey,
        });
        this.markerGroup = new H.map.Group();
        this.pixelRatio = window.devicePixelRatio || 1;
        this.defaultLayers = this.platform.createDefaultLayers({
          tileSize: this.pixelRatio === 1 ? 256 : 512,
          ppi: this.pixelRatio === 1 ? undefined : 320,
        });
        this.defaultLayers.crossOrigin = true;
        this.map = new H.Map(
          this.$refs.map,
          this.defaultLayers.vector.normal.map,
          {
            zoom: defaultZoom,
            center: {
              lat: defaultCentreLat,
              lng: defaultCentreLng,
            },
            pixelRatio: this.pixelRatio,
          },
        );
        window.addEventListener(
          'resize',
          this.handleResize,
          supportsPassiveEvents ? { passive: true } : false,
        );
        this.behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(this.map),
        );
        // Create the default UI components
        this.ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
        this.ui.setUnitSystem(
          this.imperial ? H.ui.UnitSystem.IMPERIAL : H.ui.UnitSystem.METRIC,
        );
        this.handleZoomOption();
        this.map.addObject(this.markerGroup);
        this.router = this.platform.getRoutingService(null, 8);
        this.initComplete = true;
        callback();
      }
    },
    getMap(callback) {
      let vm = this;
      function checkIfLoaded() {
        if (vm.coreLoaded) callback();
        else setTimeout(checkIfLoaded, 50);
      }
      checkIfLoaded();
    },
    recenter() {
      this.map.getViewModel().setLookAtData({ bounds: this.center });
    },
    setActivityMarker(newVal) {
      this.removeExistingActivityMarker();
      if (newVal.longitude && newVal.latitude) {
        const icon = new H.map.Icon(this.activityIcon, {
          size: {
            w: this.activityIconWidth,
            h: this.activityIconHeight,
          },
          anchor: {
            x: this.activityIconAnchorX,
            y: this.activityIconAnchorY,
          },
        });
        this.activityMarker = new H.map.Marker(
          { lat: newVal.latitude, lng: newVal.longitude },
          { icon: icon },
        );
        this.markerGroup.addObject(this.activityMarker);
        this.center = this.markerGroup.getBoundingBox();
        this.map.getViewModel().setLookAtData({
          bounds: this.center,
          zoom: this.zoomLevel,
        });
      }
    },
    removeExistingEngineerMarker() {
      if (this.markerGroup && this.engineerMarker) {
        this.markerGroup.removeObject(this.engineerMarker);
        this.engineerMarker = undefined;
      }
    },
    removeExistingActivityMarker() {
      if (this.markerGroup && this.activityMarker) {
        this.markerGroup.removeObject(this.activityMarker);
        this.activityMarker = undefined;
      }
    },
    displayRoute(lat, lng) {
      const routingParameters = {
        routingMode: 'fast',
        transportMode: 'car',
        // The start point of the route:
        origin: `${lat},${lng}`,
        // The end point of the route:
        destination: `${this.activityDetails.latitude},${this.activityDetails.longitude}`,
        return: 'polyline',
      };
      this.router.calculateRoute(
        routingParameters,
        (result) => {
          let route, routeShape, lineString;
          if (result.routes.length) {
            const arrivalTime =
              result?.routes?.[0]?.sections?.[0]?.arrival?.time;
            if (arrivalTime) {
              this.$store.dispatch(
                'setCalculatedEta',
                new Date(arrivalTime).valueOf(),
              );
            }
            lineString = H.geo.LineString.fromFlexiblePolyline(
              result.routes[0].sections[0].polyline,
            );
            this.routeLine = new H.map.Polyline(lineString, {
              style: {
                strokeColor: this.routeColor,
                lineWidth: 2,
              },
            });
            this.map.addObject(this.routeLine);
            const bounds = this.routeLine.getBoundingBox();
            const top = bounds.getTop();
            const bottom = bounds.getBottom();
            const left = bounds.getLeft();
            const right = bounds.getRight();
            // Adjust the bounds very slightly to prevent the icons;
            // hanging off the edge;
            const newBounds = {
              newTop: top + 0.00002,
              newLeft: left - 0.0008,
              newBottom: bottom - 0.00002,
              newRight: right + 0.0008,
            };
            this.center = new H.geo.Rect(
              newBounds.newTop,
              newBounds.newLeft,
              newBounds.newBottom,
              newBounds.newRight,
            );
            this.map.getViewModel().setLookAtData({ bounds });
          }
        },
        (error) => {
          console.warn(error);
        },
      );
    },
    setEngineerMarker(newVal) {
      this.removeExistingEngineerMarker();
      if (newVal?.latitude && newVal?.longitude) {
        const icon = new H.map.Icon(this.engineerIcon, {
          size: {
            w: this.engineerIconWidth,
            h: this.engineerIconHeight,
          },
          asCanvas: true,
          anchor: {
            x: this.engineerIconAnchorX,
            y: this.engineerIconAnchorY,
          },
        });
        this.engineerMarker = new H.map.Marker(
          { lat: newVal.latitude, lng: newVal.longitude },
          { icon: icon },
        );
        this.markerGroup.addObject(this.engineerMarker);
        this.displayRoute(newVal.latitude, newVal.longitude);
      }
    },
  },
});
</script>

<style scoped>
.here-map {
  margin-top: 0 0 0 0;
}

.custom-button {
  border-radius: 26px;
  width: 9em;
}
</style>
