<template>
  <div class="modal-card" style="width: auto">
    <b-loading v-model="apptsLoading" :is-full-page="false"></b-loading>
    <section class="modal-card-body">
      <p class="has-text-weight-semibold has-text-primary is-size-5">
        Choose a date to reschedule your appointment
      </p>
      <div class="columns" style="margin-top: 1rem">
        <div class="column">
          <v-date-picker
            is-expanded
            color="red"
            :value="selectedDate"
            :attributes="computedAttributes"
            @dayclick="handleClickedDate"
          />
        </div>
        <div class="column" style="min-width: 300px">
          <div class="has-text-centered has-text-primary">
            <p class="has-text-weight-semibold" style="height: 1.5rem">
              {{ formattedDate }}
            </p>
          </div>
          <b-table
            class="appt-table"
            :data="selectedDateTimeslots"
            :selected.sync="selectedTimeslot"
            focusable
            :mobile-cards="false"
            :height="'150px'"
          >
            <b-table-column
              v-slot="props"
              field="label"
              sortable
              label="Select your new slot"
              >{{ props.row.label }}</b-table-column
            >
          </b-table>
          <div class="has-text-centered">
            <b-button
              :disabled="!selectedTimeslot"
              type="is-primary"
              style="margin-top: 0.5em"
              @click="bookAppointment"
              >Reschedule Appointment</b-button
            >
          </div>
        </div>
      </div>
      <appointment-legend />
    </section>
  </div>
</template>

<script>
import { parse } from 'date-fns';
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
import { getAppointmentData } from '@/services/reschedule';
import AppointmentLegend from './AppointmentLegend';
export default {
  name: 'AppointmentCalendar',
  components: {
    VDatePicker,
    AppointmentLegend,
  },
  data() {
    return {
      selectedDate: undefined,
      selectedDateTimeslots: [],
      selectedTimeslot: undefined,
      appointmentsData: undefined,
      apptsLoading: false,
    };
  },
  computed: {
    formattedDate() {
      if (!this.selectedDate) return null;
      return this.$d(this.selectedDate);
    },
    computedAttributes() {
      if (!this.appointmentsData) return null;
      const attributes = [];
      const normalAppointments = {
        key: 'normal',
        highlight: 'orange',
        fillMode: 'light',
        dates: [],
        customData: {},
      };
      const ecoAppointments = {
        key: 'eco',
        highlight: 'blue',
        fillMode: 'solid',
        dates: [],
        customData: {},
      };
      const bestEcoAppointments = {
        key: 'eco',
        fillMode: 'solid',
        dates: [],
        customData: {},
        highlight: 'green',
      };
      attributes.push(normalAppointments);
      attributes.push(ecoAppointments);
      attributes.push(bestEcoAppointments);
      this.appointmentsData.availableAppointments.forEach((appointment) => {
        const bestDate = parse(
          this.appointmentsData.singledOutAppointments.bestEcoAppointment.date,
          'yyyy-MM-dd',
          new Date(),
        );
        bestEcoAppointments.dates.push(bestDate);
        bestEcoAppointments.customData[
          this.appointmentsData.singledOutAppointments.bestEcoAppointment.date
        ] = this.appointmentsData.singledOutAppointments.bestEcoAppointment.timeslots;
        if (appointment.timeslots.length > 0) {
          const appDate = parse(appointment.date, 'yyyy-MM-dd', new Date());
          // we have some availability
          if (appointment?.highlights?.numberOfNearbyJobs > 0) {
            ecoAppointments.dates.push(appDate);
            ecoAppointments.customData[appointment.date] =
              appointment.timeslots;
            return;
          }
          normalAppointments.dates.push(appDate);
          normalAppointments.customData[appointment.date] =
            appointment.timeslots;
        }
      });
      return attributes;
    },
  },
  async created() {
    try {
      this.apptsLoading = true;
      this.appointmentsData = await getAppointmentData(
        this.activityDetails,
        this.token,
      );
    } finally {
      this.apptsLoading = false;
    }
  },
  methods: {
    handleClickedDate(evt) {
      this.selectedTimeslot = undefined;
      this.selectedDateTimeslots = evt.attributes[0].customData[evt.id];
      this.selectedDate = evt.date;
    },
    async bookAppointment() {
      this.apptsLoading = true;
      setTimeout(async () => {
        await this.$store.dispatch(
          'setRescheduledDate',
          this.selectedDate.valueOf(),
        );
        await this.$store.dispatch(
          'setRescheduledTimeslot',
          this.selectedTimeslot,
        );
        this.apptsLoading = false;
        this.$emit('close');
      }, 2000);
    },
  },
};
</script>
