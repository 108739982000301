<script>
import axios from 'axios';
import i18n from '@/lang/lang';
import { mapGetters, mapActions } from 'vuex';
import { isToday, isYesterdayOrOlder } from '@/utils/index';

export default {
  data() {
    return {
      engineerIconWidth: this.$omwConfig.display.engineerIcon.width,
      engineerIconHeight: this.$omwConfig.display.engineerIcon.height,
      engineerIconAnchorX: this.$omwConfig.display.engineerIcon.anchorX,
      engineerIconAnchorY: this.$omwConfig.display.engineerIcon.anchorY,
      activityIconWidth: this.$omwConfig.display.activityIcon.width,
      activityIconHeight: this.$omwConfig.display.activityIcon.height,
      activityIconAnchorX: this.$omwConfig.display.activityIcon.anchorX,
      activityIconAnchorY: this.$omwConfig.display.activityIcon.anchorY,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(['error', 'isLoading', 'activityDetails', 'engineerDetails']),
    language() {
      const lang = navigator.language;
      return lang;
    },
    feedbackEnabled() {
      return this.$omwConfig?.feedback?.enabled;
    },
    notificationEnabled() {
      return this.$omwConfig?.display?.notification?.show;
    },
    engineerName() {
      return (
        this.engineerDetails.nameOverride ||
        this.engineerDetails.Nickname ||
        this.engineerDetails.name ||
        this.$omwConfig.display.defaultEngineerName
      );
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
    showDisclaimer() {
      return (
        this.activityDetails &&
        this.activityDetails.status !== 'completed' &&
        this.activityDetails.status !== 'started'
      );
    },
    activityIcon() {
      return this.$omwConfig.display.activityIcon.svg;
    },
    engineerIcon() {
      return this.$omwConfig.display.engineerIcon.svg;
    },
    token() {
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);
      return params.get('token');
    },
    currentYear() {
      return new Date().getFullYear();
    },
    activityForToday() {
      return this?.activityDetails?.date
        ? isToday(this.activityDetails.date)
        : false;
    },
    activityYesterdayOrOlder() {
      return this?.activityDetails?.date
        ? isYesterdayOrOlder(this.activityDetails.date)
        : false;
    },
  },
  mounted() {
    window.addEventListener('resize', this.widthListenerCallBack);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.widthListenerCallBack);
  },
  methods: {
    ...mapActions([
      'setLoading',
      'setError',
      'setActivityDetails',
      'setEngineerDetails',
      'setCurrentDate',
      'setLoadingText',
    ]),
    getOrientation(x) {
      if (x === undefined) {
        return 'desktop';
      } else {
        let y;
        if (x === 90 || x === -90) {
          y = 'landscape';
        } else {
          y = 'portrait';
        }
        return y;
      }
    },
    widthListenerCallBack() {
      this.windowWidth = window.innerWidth;
    },
    switchLang(lang) {
      if (lang in this.$i18n.messages) {
        this.$i18n.locale = lang;
      } else {
        this.loadLocaleMessage(lang, (err, message) => {
          if (err) {
            return;
          }
          i18n.setLocaleMessage(lang, message);
          this.$i18n.locale = lang;
        });
      }
    },
    setLang(lang) {
      this.$store.dispatch('setLang', lang);
    },
    setLangNew(lang) {
      this.$store.dispatch('setLangNew', lang);
    },
    loadLocaleMessage(locale, cb) {
      return fetch(`@/lang/${locale}.json`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (Object.keys(json).length === 0) {
            return Promise.reject(new Error('locale empty !!'));
          } else {
            return Promise.resolve(json);
          }
        })
        .then((message) => {
          cb(null, message);
        })
        .catch((error) => {
          cb(error);
        });
    },
    async getData(isUpdate = false) {
      if (isUpdate) {
        this.setLoadingText(
          this.$t('Refreshing information for your appointment...'),
        );
      } else {
        this.setLoadingText(this.$t('Finding your appointment...'));
      }
      this.setLoading(true);
      this.setCurrentDate(Date.now());
      if (this.token === 'demo') {
        this.setActivityDetails(this.$omwConfig.demo.activityDetails);
        this.setEngineerDetails(this.$omwConfig.demo.engineerDetails);
        this.setLoading(false);
        return;
      }
      try {
        const { data } = await axios.get(
          `${this.$omwConfig.omwServer.url}=${this.token}`,
          {
            headers: {
              'x-api-key': this.$omwConfig.omwServer.apiKey,
            },
            crossdomain: true,
            timeout: 30000,
          },
        );
        this.setActivityDetails(data.activity);
        this.setEngineerDetails(data.resource);
        if (this.$omwConfig.intercom.enabled && this.$intercom) {
          this.$intercom.update({
            ApptNo: data.activity.apptNumber,
            OMW: window.location.href,
          });
        }
      } catch (err) {
        console.error(err);
        this.setError(true);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
