<template>
  <div>
    <div class="centered" style="padding-top: 2em">
      <img
        :src="require(`@/assets/img/${$omwConfig.display.companyLogo}`)"
        style="width: 15vw; padding-top: 5em"
      />
    </div>
    <b-loading :is-full-page="true" :active.sync="isLoading" />

    <p
      class="has-text-weight-semibold is-size-4 has-text-grey has-text-centered is-italic"
      style="padding-top: 5em"
    >
      {{ loadingText }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  computed: {
    ...mapGetters(['loadingText']),
  },
});
</script>

<style scoped>
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
