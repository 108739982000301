import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueTimeago from 'vue-timeago';
import Buefy from 'buefy';

import App from './App.vue';
import i18n from './lang/lang';
import store from '@/store';
import globalData from '@/mixins/globalData.vue';

import config from '@/config/config';

if (config.intercom.enabled) {
  const VueIntercom = require('vue-intercom');
  Vue.use(VueIntercom, {
    appId: config.intercom.appId,
    hide_default_launcher: false,
  });
}

Vue.prototype.$omwConfig = config;

import { library } from '@fortawesome/fontawesome-svg-core';

import { faEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpenText';
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faMobile } from '@fortawesome/pro-solid-svg-icons/faMobile';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faChevronDoubleDown } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleDown';
import { faChevronDoubleUp } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleUp';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

import { extend, setInteractionMode } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';

setInteractionMode('eager');
extend('email', {
  ...email,
  message: 'Please provide a valid email so we can contact you',
});

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add([
  faFileSignature,
  faEnvelopeOpenText,
  faMobile,
  faStar,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faArrowDown,
  faArrowUp,
  faCheckCircle,
  faCalendarAlt,
]);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(VueTimeago, {
  locales: {
    // de: require('../node_modules/vue-timeago/node_modules/date-fns/locale/de'),
    en: require('../node_modules/vue-timeago/node_modules/date-fns/locale/en'),
  },
});
Vue.mixin(globalData);

Vue.config.productionTip = false;

export const app = new Vue({
  render: (h) => h(App),
  store,
  i18n,
}).$mount('#app');
