import configData from '@/config/config';
import axios from 'axios';

const apiClient = axios.create({
  baseURL: configData.reschedule.url,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export async function getAppointmentData(appointmentDetails, token) {
  const query = new URLSearchParams();
  query.append('apptNumber', appointmentDetails.apptNumber);
  query.append('token', token);
  const params = {
    query,
  };
  const { data } = await apiClient(params);
  return data;
}
