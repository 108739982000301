<template>
  <div id="app" class="hero is-fullheight">
    <custom-loading v-if="isLoading" :key="'loading'" />
    <div class="hero-body">
      <transition-group v-if="!isLoading" name="fade">
        <div v-if="!showError" :key="'maingroup'" style="padding-bottom: 1em">
          <main-container />

          <feedback-container
            v-if="feedbackEnabled"
            feedbackstyle="cursor: pointer;"
            @feedback="recordFeedback"
            @feedback-active="setHideIntercom"
          />
        </div>
        <error v-if="showError" :key="'error'" />
      </transition-group>
    </div>
    <div class="hero-foot">
      <the-footer />
    </div>
    <div
      id="notificationContainer"
      ref="notificationContainer"
      class="notification-container"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import MainContainer from '@/components/MainContainer';
import CustomLoading from '@/components/CustomLoading';
import {
  setHours,
  setMinutes,
  setSeconds,
  differenceInMinutes,
  getDay,
} from 'date-fns';
export default {
  name: 'App',
  components: {
    MainContainer,
    CustomLoading,
    Error: () => import(/* webpackPrefetch: true */ '@/components/Error'),
    TheFooter: () =>
      import(/* webpackPrefetch: true */ '@/components/layout/TheFooter'),
    FeedbackContainer: () =>
      import(
        /* webpackPrefetch: true */ '@/components/optional/feedback/FeedbackContainer'
      ),
  },
  data() {
    return {
      hideIntercomLauncher: false,
      isIntercomOnlineBucket: false,
      intervalHandle: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'activityDetails',
      'shouldShowIntercom',
      'dismissNotification',
    ]),
    showError() {
      return (this.activityYesterdayOrOlder || this.error) && !this.isLoading;
    },
    isIntercomOnlineDay() {
      if (
        !this.$omwConfig.intercom ||
        !this.$omwConfig.intercom.enabled ||
        !this.$omwConfig.intercom.onlineDays
      )
        return false;
      const now = new Date();
      const todayDay = getDay(now);
      const onlineDays = Object.keys(this.$omwConfig.intercom.onlineDays);
      if (onlineDays.includes(todayDay + '')) {
        return true;
      } else {
        return false;
      }
    },
    intercomOnlineTime() {
      if (this.$omwConfig.intercom.enabled) {
        const now = new Date();
        const day = getDay(now);
        const offlineTimeToday = this.$omwConfig.intercom.onlineDays[day];
        if (!offlineTimeToday) return null;
        const timeParts = offlineTimeToday.showTime.split(':');
        let onlineTime = setHours(now, parseInt(timeParts[0]));
        onlineTime = setMinutes(onlineTime, parseInt(timeParts[1]));
        onlineTime = setSeconds(onlineTime, 0);
        return onlineTime;
      }
      return null;
    },
    intercomOfflineTime() {
      if (this.$omwConfig.intercom.enabled) {
        const now = new Date();
        const day = getDay(now);
        const offlineTimeToday = this.$omwConfig.intercom.onlineDays[day];
        if (!offlineTimeToday) return null;
        const timeParts = offlineTimeToday.hideTime.split(':');
        let offlineTime = setHours(now, parseInt(timeParts[0]));
        offlineTime = setMinutes(offlineTime, parseInt(timeParts[1]));
        offlineTime = setSeconds(offlineTime, 0);
        return offlineTime;
      }
      return null;
    },
  },
  watch: {
    hideIntercomLauncher: {
      handler(newVal) {
        if (!this.$omwConfig.intercom.enabled) {
          return;
        }
        // If value is actually changing and not from an immediate boot
        if (newVal === false) {
          setTimeout(() => {
            this.bootIntercom(newVal);
          }, this.$omwConfig.feedback.timeout);
        } else {
          this.bootIntercom(newVal);
        }
      },
    },
    engineerDetails: {
      handler() {
        if (!this.$omwConfig.intercom.onlineBuckets) {
          this.isIntercomOnlineBucket = true;
          return;
        }
        this.isIntercomOnlineBucket = this.$omwConfig.intercom.onlineBuckets.includes(
          this.engineerDetails.parentId,
        );
      },
    },
  },
  async created() {
    await this.$store.dispatch('setRescheduledDate', undefined);
    await this.$store.dispatch('setRescheduledTimeslot', undefined);
    this.setError(false);
    this.currentDate = new Date();
    if (this.$omwConfig.intercom.enabled) {
      this.calculateIfOnline();
      this.bootIntercom(false);
      this.intervalHandle = setInterval(async () => {
        this.calculateIfOnline();
        this.bootIntercom(this.hideIntercomLauncher);
      }, 5000);
    }
    this.handleShowNotification();
  },
  async mounted() {
    if (!this.token) {
      this.setError(true);
      this.setLoading(false);
    } else {
      await this.getData(false);
    }
  },
  beforeDestroy() {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
    }
  },
  methods: {
    ...mapActions([
      'setCurrentDate',
      'setShouldShowIntercom',
      'setDismissNotification',
    ]),
    handleShowNotification() {
      if (this.notificationEnabled && !this.dismissNotification) {
        this.$buefy.dialog.alert({
          title: this.$omwConfig.display.notification.title,
          message: this.$omwConfig.display.notification.message,
          type: 'is-primary',
          ariaRole: 'alertdialog',
          ariaModal: true,
          onConfirm: () => {
            this.setDismissNotification(true);
          },
          size: 'is-medium',
          container: 'notificationContainer',
        });
      }
    },
    setHideIntercom(evt) {
      this.hideIntercomLauncher = evt;
    },
    bootIntercom(shouldHide) {
      // We're outside of defined hours so we shouldn't show
      if (!this.shouldShowIntercom) {
        this.$nextTick(() => {
          this.$intercom.boot({
            ApptNo: this.activityDetails.apptNumber,
            Bucket: this.engineerDetails.parentId,
            hide_default_launcher: true,
          });
        });
      } else {
        this.$nextTick(() => {
          this.$intercom.boot({
            ApptNo: this.activityDetails.apptNumber,
            Bucket: this.engineerDetails.parentId,
            hide_default_launcher: shouldHide,
            OMW: window.location.href,
          });
          this.$intercom.update({
            ApptNo: this.activityDetails.apptNumber,
            Bucket: this.engineerDetails.parentId,
            OMW: window.location.href,
          });
        });
      }
    },
    calculateIfOnline() {
      const now = new Date();
      if (!this.isIntercomOnlineBucket) {
        return false;
      }
      if (!this.intercomOnlineTime || !this.intercomOfflineTime) {
        return false;
      }
      // First check it's a day when intercom should show
      if (!this.isIntercomOnlineDay) return false;
      const diffStartToNow = differenceInMinutes(this.intercomOnlineTime, now);
      const diffNowToEnd = differenceInMinutes(now, this.intercomOfflineTime);
      if (diffStartToNow <= 0 && diffNowToEnd <= 0) {
        this.setShouldShowIntercom(true);
      } else {
        this.setShouldShowIntercom(false);
      }
    },
    async recordFeedback(evt) {
      const feedbackResponse = evt;
      feedbackResponse.appointmentNumber = this.activityDetails.apptNumber;
      feedbackResponse.feedbackDate = new Date().valueOf();
      feedbackResponse.token = this.token;
      feedbackResponse.url = window.location.href;
      const url = this.$omwConfig.feedback.url;
      const options = {
        data: feedbackResponse,
        timeout: 30000,
      };
      try {
        await axios.post(url, options);
      } catch (err) {
        this.$buefy.toast.open({
          message: this.$t(
            'Something went wrong when submitting your feedback, please try again',
          ),
          type: 'is-danger',
          duration: this.$omwConfig.feedback.timeout,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: '#F2F2F2' !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.notification-container {
  position: fixed;
  width: 80vw;
  z-index: 999;
}
</style>
