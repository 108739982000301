<template>
  <div>
    <div>
      <div class="card-header-title" :style="headerStyleObject">
        <a :href="websiteUrl" target="_new"
          ><img
            :src="require(`../assets/img/${$omwConfig.display.companyLogo}`)"
            :style="headerImageStyleObject"
            style="text-align: left"
          />
        </a>
      </div>
    </div>
    <div v-if="!rescheduledDate">
      <div class="columns">
        <div v-show="activityDetails" class="column is-half">
          <appointment-details :width="mapWidth" />
          <here-map
            :height="mapHeight"
            :width="$omwConfig.here.mapWidth"
            :api-key="$omwConfig.here.apiKey"
            :imperial="$omwConfig.here.imperial"
            :show-zoom="$omwConfig.here.showZoom"
          />
        </div>
        <div class="column is-half">
          <engineer-panel
            ref="engineerPanel"
            :photo-source="photoSource"
            :logos="logos"
            :licence-plate="'RE01 7GH'"
            style="margin-top: 1em"
          />
          <div v-if="marketingEnabled">
            <marketing
              v-show="marketingReady"
              ref="marketing"
              @image-loaded="nowShowMarketing"
            />
            <b-skeleton
              :height="engineerPanelHeight"
              size="is-large"
              :active="!marketingReady"
            ></b-skeleton>
          </div>
          <div class="has-text-centered mt-1">
            <!-- eslint-disable vue/no-v-html -->
            <!-- <p
            class="is-size-5 has-text-weight-semibold"
            v-html="$t('Need to rearrange or cancel an appointment?')"
          >
            <wbr />
          </p> -->
            <!-- <span class="avoidwrap">
            <a
              class="is-size-5"
              style="text-decoration: none; font-weight: 800"
              :href="`tel:${$omwConfig.display.contactCentreNo}`"
            >
              {{ $omwConfig.display.contactCentreNo }}</a
            >
          </span> -->
            <!-- eslint-enable vue/no-v-html -->
            <div class="column" style="text-align: center; padding: 1em 0em">
              <div class="mb-1">
                <b-button
                  type="is-info"
                  class="has-text-weight-semibold"
                  icon-left="calendar-alt"
                  @click="showReschedule"
                  >Click to reschedule</b-button
                >
              </div>
              <i class="is-size-5"
                >{{ $t('Last updated') }}
                <timeago
                  :datetime="currentDate"
                  :auto-update="60"
                  :locale="language"
                />
              </i>
            </div>
          </div>
        </div>

        <span
          v-if="trustpilotEnabled"
          class="column"
          style="display: block; max-height: 40px; padding: 2em 0em 5em 0em"
        >
          <trustpilot
            :style="trustpilotPadding"
            :data-locale="$omwConfig.trustpilot.dataLocale"
            :data-template-id="$omwConfig.trustpilot.dataTemplateId"
            :data-business-unit-id="$omwConfig.trustpilot.dataBusinessUnitId"
            :data-style-height="$omwConfig.trustpilot.dataStyleHeight"
            :data-style-width="$omwConfig.trustpilot.dataStyleWidth"
            :data-theme="$omwConfig.trustpilot.dataTheme"
            :url="$omwConfig.trustpilot.url"
          />
        </span>
      </div>
    </div>
    <div v-else>
      <appointment-confirmation style="height: 80vh" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import AppointmentDetails from '@/components/AppointmentDetails';
import EngineerPanel from '@/components/EngineerPanel';
import AppointmentCalendar from '@/components/appointmentBooking/AppointmentCalendar';
import AppointmentConfirmation from '@/components/appointmentBooking/AppointmentConfirmation';
import HereMap from '@/components/Heremap';

export default defineComponent({
  components: {
    AppointmentDetails,
    HereMap,
    EngineerPanel,
    Trustpilot: () =>
      import(/* webpackPrefetch: true */ '@/components/optional/Trustpilot'),
    Marketing: () =>
      import(/* webpackPrefetch: true */ '@/components/optional/Marketing'),
    AppointmentConfirmation,
  },
  data() {
    return {
      mapMounted: false,
      engineerPanelHeight: undefined,
      headerStyleObject: {
        height: this.$omwConfig.display.headerHeight,
      },
      headerImageStyleObject: {
        height: this.$omwConfig.display.imageHeight,
        width: this.$omwConfig.display.imageWidth,
      },
      websiteUrl: this.$omwConfig.display.customerWebsite,
      logos: this.$omwConfig.logos,
      marketingReady: false,
    };
  },
  computed: {
    ...mapGetters(['currentDate', 'rescheduledDate']),
    photoSource() {
      const engineerKeys = Object.keys(this.engineerDetails);
      if (
        !engineerKeys.includes('photo') ||
        this.engineerDetails['photo'].match(/No photo available/gi)
      ) {
        return undefined;
      }
      return this.engineerDetails['photo'];
    },
    marketingEnabled() {
      return this.$omwConfig?.marketing?.enabled;
    },
    trustpilotEnabled() {
      return this.$omwConfig?.trustpilot?.enabled;
    },
    trustpilotPadding() {
      let paddingTop = '10em';
      return {
        paddingBottom: '1em',
        paddingTop,
      };
    },
    relativeToMarketing() {
      return {
        height: this.marketingHeight,
        width: '30em',
      };
    },
    marketingHeight() {
      return 10;
    },
    mapHeight() {
      if (this.isMobile) {
        return this.$omwConfig.here.mapHeightMobile;
      }
      return this.$omwConfig.here.mapHeightDesktop;
    },
    mapWidth() {
      if (this.isMobile) {
        return this.$omwConfig.here.mapWidthMobile;
      }
      return this.$omwConfig.here.mapWidthDesktop;
    },
    disclaimerStyle() {
      return {
        fontStyle: 'italic',
        color: 'black',
      };
    },
  },
  updated() {
    this.engineerPanelHeight = this.$refs?.engineerPanel?.$el?.offsetHeight;
  },
  methods: {
    nowShowMarketing(evt) {
      this.marketingReady = true;
    },
    showReschedule() {
      this.$buefy.modal.open({
        parent: this,
        component: AppointmentCalendar,
        hasModalCard: true,
        events: {
          'new-appointment': () => {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Appointment rescheduled successfully',
              type: 'is-success',
            });
          },
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
$margin: 0.25rem;

.content-desktop {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
}
.content-mobile {
  display: flex;
}
@media screen and (max-width: 768px) {
  .content-desktop {
    display: none;
  }
  .content-mobile {
    display: block;
  }
}

.engineer-marketing-flex-container {
  display: flex;
  flex-flow: wrap row-reverse;
  margin: 0 -$margin;
  text-align: center;
}

.margin-wrap {
  flex: 1 1 auto;
  padding: 1rem;
  border: $margin;
}

span.avoidwrap {
  display: inline-block;
}
</style>
