import { app } from '../main';
import { parse, isFuture, format, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { calculateEtaBuffer } from '@/utils';

function isInFuture(assignedDate) {
  if (assignedDate) {
    const comparisonDate = parse(assignedDate, 'yyyy-MM-dd', new Date());
    comparisonDate.setHours(0);
    comparisonDate.setMinutes(0);
    comparisonDate.setSeconds(0);
    comparisonDate.setMilliseconds(0);
    return isFuture(comparisonDate);
  } else {
    return true;
  }
}

export const state = {
  lang: 'en',
  error: false,
  isLoading: true,
  activityDetails: {},
  engineerDetails: {},
  currentDate: new Date(),
  shouldShowIntercom: false,
  loadingText: '',
  dismissNotification: false,
  mapInitComplete: false,
  calculatedEta: undefined,
  rescheduledDate: undefined,
  rescheduledTimeslot: undefined,
};

export const mutations = {
  SET_DISMISS_NOTIFICATION(state, payload) {
    state.dismissNotification = payload;
  },
  SET_LOADING_TEXT(state, payload) {
    state.loadingText = payload;
  },
  SET_SHOW_INTERCOM(state, payload) {
    state.shouldShowIntercom = payload;
  },
  SET_LANG(state, payload) {
    app.$i18n.locale = payload;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
  SET_ACTIVITY_DETAILS(state, activityDetails) {
    state.activityDetails = activityDetails;
  },
  SET_ENGINEER_DETAILS(state, engineerDetails) {
    state.engineerDetails = engineerDetails;
  },
  SET_CURRENT_DATE(state, currentDate) {
    state.currentDate = currentDate;
  },
  SET_MAP_INIT_COMPLETE(state, initComplete) {
    state.mapInitComplete = initComplete;
  },
  SET_CALCULATED_ETA(state, eta) {
    state.calculatedEta = eta;
  },
  SET_RESCHEDULED_DATE(state, apptDate) {
    state.rescheduledDate = apptDate;
  },
  SET_RESCHEDULED_TIMESLOT(state, timeslot) {
    state.rescheduledTimeslot = timeslot;
  },
};

export const actions = {
  setDismissNotification({ commit }, payload) {
    commit('SET_DISMISS_NOTIFICATION', payload);
  },
  setLoadingText({ commit }, payload) {
    commit('SET_LOADING_TEXT', payload);
  },
  setShouldShowIntercom({ commit }, payload) {
    commit('SET_SHOW_INTERCOM', payload);
  },
  setLang({ commit }, payload) {
    commit('SET_LANG', payload);
  },
  async setLangNew({ commit }, payload) {
    if (payload in app.$i18n.messages) {
      commit('SET_LANG', payload);
    } else {
      try {
        const res = await import(`@/lang/${payload}.json`);
        app.$i18n.setLocaleMessage(payload, res.data);
        commit('SET_LANG', payload);
      } catch (e) {
        // nothing to do
      }
    }
  },
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading);
  },
  setError({ commit }, error) {
    commit('SET_ERROR', error);
  },
  setEngineerDetails({ commit }, engineerDetails) {
    commit('SET_ENGINEER_DETAILS', engineerDetails);
  },
  setActivityDetails({ commit }, activityDetails) {
    commit('SET_ACTIVITY_DETAILS', activityDetails);
  },
  setCurrentDate({ commit }, currentDate) {
    commit('SET_CURRENT_DATE', currentDate);
  },
  setMapInitComplete({ commit }, initComplete) {
    commit('SET_MAP_INIT_COMPLETE', initComplete);
  },
  setCalculatedEta({ commit }, eta) {
    commit('SET_CALCULATED_ETA', eta);
  },
  setRescheduledDate({ commit }, apptDate) {
    commit('SET_RESCHEDULED_DATE', apptDate);
  },
  setRescheduledTimeslot({ commit }, timeslot) {
    commit('SET_RESCHEDULED_TIMESLOT', timeslot);
  },
};

export const getters = {
  dismissNotification: (state) => state.dismissNotification,
  isLoading: (state) => state.isLoading,
  loadingText: (state) => state.loadingText,
  error: (state) => state.error,
  activityDetails: (state) => state.activityDetails,
  engineerDetails: (state) => state.engineerDetails,
  currentDate: (state) => state.currentDate,
  formattedDate: (state) => {
    if (!state.activityDetails.date) return;
    let dateObj = parse(state.activityDetails.date, 'yyyy-MM-dd', new Date());
    if (!isValid(dateObj)) {
      dateObj = parse(state.activityDetails.date, 'dd-MM-yyyy', new Date());
    }
    const formattedDate = format(dateObj, 'do MMMM yyyy', {
      locale: enGB,
    });
    return formattedDate;
  },
  apptInFuture: (state) => {
    const future = isInFuture(state.activityDetails.date);
    return future;
  },
  shouldShowIntercom: (state) => state.shouldShowIntercom,
  mapInitComplete: (state) => state.mapInitComplete,
  calculatedEta: (state) => {
    if (!state.calculateEtaBuffer) return undefined;
    return calculateEtaBuffer(state.calculatedEta);
  },
  rescheduledDate: (state) => {
    if (state.rescheduledDate) {
      return new Date(state.rescheduledDate);
    }
    return null;
  },
  rescheduledTimeslot: (state) => state.rescheduledTimeslot,
};
