<template>
  <div class="content">
    <div class="columns is-mobile aligned">
      <div class="column is-1"></div>
      <div class="column is-half has-text-centered">
        <img v-if="photoSource" class="engineer-photo" :src="photoSource" />
        <div v-if="engineerMessage" class="has-text-weight-semibold">
          {{ engineerMessage }}
        </div>
      </div>
      <div class="column">
        <p
          v-if="funFact"
          class="has-text-weight-semibold is-italic"
          style="margin-right: 1em"
        >
          {{ funFact }}
        </p>
      </div>
    </div>
    <div class="columns is-mobile" style="flex-direction: row-reverse">
      <div class="column"></div>
      <div v-for="logo in logos" :key="logo.filename" class="column">
        <logo-container
          :logo="logo.filename"
          :alt-text="logo.label"
          :title="logo.label"
          :value="logo.value"
        />
      </div>
      <div class="column is-5">
        <licence-plate
          v-if="showLicensePlate"
          :value="licencePlate"
          :country="$omwConfig.display.licencePlateCountry"
        />
        <div v-else class="has-text-weight-semibold is-size-5">
          {{ $t('Licence Plate') }}: {{ licencePlate }}
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import LogoContainer from '@/components/LogoContainer';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    LogoContainer,
    LicencePlate: () =>
      import(/* webpackPrefetch: true */ '@/components/optional/LicencePlate'),
  },
  props: {
    photoSource: {
      type: String,
      required: false,
      default: undefined,
    },
    licencePlate: {
      type: String,
      required: false,
      default: '',
    },
    logos: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    showLicensePlate() {
      return this.$omwConfig.display.showLicensePlate && this.licencePlate;
    },
    funFact() {
      return this?.engineerDetails?.funFact;
    },
    engineerMessage() {
      let msg = '';
      if (
        this.engineerDetails.nameOverride ===
        this.$omwConfig.display.defaultEngineerName
      ) {
        return msg;
      }
      if (!this.engineerName) {
        return this.$t(
          'Your technician may contact you in relation to your appointment',
        );
      }
      if (this.activityForToday) {
        if (this.activityDetails.status === 'pending') {
          msg += this.$t('Your engineer today will be');
          msg += ' ' + this.engineerName + ' ';
          msg += this.$t('who will contact you prior to arriving');
        } else if (this.activityDetails.status === 'started') {
          msg += this.$t('Your engineer today is');
          msg += ' ' + this.engineerName;
        } else {
          msg += this.$t('Your engineer today was');
          msg += ' ' + this.engineerName;
        }
      } else {
        msg = this.$t(
          `We'll confirm your engineer tomorrow and they'll contact you prior to arriving`,
        );
      }
      return msg;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.content {
  background-color: $engineer-panel;
  color: $engineer-panel-text;
  border-radius: 10px;
}
.engineer-photo {
  border-radius: 50%;
  max-width: 80px;
}
.aligned {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
