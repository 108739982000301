import {
  add,
  format,
  isSameDay,
  isBefore,
  parse,
  setMinutes,
  setHours,
  setSeconds,
  setMilliseconds,
  differenceInDays,
} from 'date-fns';

import configData from '@/config/config';

const TIME_FORMAT = 'HH:mm';

export function calculateEtaBuffer(etaDateTime) {
  const bufferedStartDate = format(
    add(etaDateTime, {
      minutes: -configData.display.eta.bufferBeforeMins,
    }),
    TIME_FORMAT,
  );
  const bufferedEndDate = format(
    add(etaDateTime, {
      minutes: configData.display.eta.bufferAfterMins,
    }),
    TIME_FORMAT,
  );
  return {
    bufferedStartDate,
    bufferedEndDate,
  };
}
export function loadCss(url, implementationCode, location) {
  const cssTag = document.createElement('link');
  cssTag.type = 'text/css';
  cssTag.rel = 'stylesheet';
  cssTag.href = url;
  cssTag.onload = implementationCode;
  location.append(cssTag);
}
export function loadJs(
  url,
  implementationCode,
  location,
  async = false,
  defer = false,
) {
  const scriptTag = document.createElement('script');
  scriptTag.src = url;
  scriptTag.async = async;
  scriptTag.defer = defer;
  scriptTag.onload = implementationCode;
  scriptTag.onreadystatechange = implementationCode;
  location.appendChild(scriptTag);
}
export function isToday(dateString) {
  const now = new Date();
  const apptDate = parse(dateString, 'yyyy-MM-dd', now);
  return isSameDay(now, apptDate);
}

export function isYesterdayOrOlder(dateString) {
  const now = new Date();
  const apptDate = parse(dateString, 'yyyy-MM-dd', now);
  const diff = differenceInDays(now, apptDate);
  return diff > 0 ? true : false;
}
